import Driver from "../assets/driver-1 1.png";
import { useNavigate } from "react-router-dom";
export default function DriverLanding() {
  const navigate = useNavigate();
  return (
    <div className="relative min-h-screen flex items-center justify-center">
      <div className="absolute  z-0">
        <img
          src={Driver}
          alt="Driver background"
          fill
          className="object-cover brightness-75"
          priority
        />
      </div>

      <div className="relative z-10 max-w-3xl mx-auto px-4 py-16 text-white text-center">
      <h1 className="font-serif text-2xl md:text-4xl font-bold mb-2 leading-snug">
          Become A Driver <br />
          Your Time. Your Goals. You're The <br />
          Boss.
        </h1>
        <div className=" ">
          <h3 className="font-serif  text-lg md:text-2xl mt-8">
            Subscribe And Join Us
          </h3>
          <div className="relative w-full md:w-[500px] lg:w-[650px] mt-3 ">
            <input 
              type="email" 
              placeholder="EMAIL" 
              className="px-4 py-3 text-sm md:text-lg rounded border border-gray-300 w-full pr-20" 
            />
            <button 
              onClick={() => navigate("/ComingSoon")}
              className="absolute top-1/2 right-3 transform -translate-y-1/2 px-5 py-2 bg-orange-500 text-white text-sm md:text-lg rounded">
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}


