import React from 'react';
import img from "../assets/image 48.png"
import icon1 from "../assets/image(6).png"
import icon2 from "../assets/image(5).png"

export default function SelectUserType() {
  return (
    <div className="flex flex-col lg:flex-row items-center justify-center min-h-screen bg-gray-100 p-6">
      {/* Left Image Section */}
      <div className="lg:w-1/2 w-full flex justify-center items-center mb-8 lg:mb-0">
        <img
          src={img} // Replace with your car image URL
          alt="Car"
          className="rounded-lg shadow-lg w-full max-w-lg"
        />
      </div>

      {/* Right Content Section */}
      <div className="lg:w-1/2 w-full bg-white p-8 rounded-lg shadow-lg max-w-md">
        <h2 className="text-2xl font-semibold text-gray-800 text-center mb-6">
          Select User Type
        </h2>
        <div className="grid grid-cols-2 gap-4 mb-6">
          {/* Vehicle Owners Option */}
          <div className="flex flex-col items-center border rounded-lg p-4 hover:shadow-lg transition">
            <img
              src={icon1} // Replace with vehicle owner image URL
              alt="Vehicle Owners"
              className="w-28 h-28 mb-2"
            />
            <p className="text-gray-700 font-medium text-center">Vehicle Owners</p>
          </div>
          {/* Customers Option */}
          <div className="flex flex-col items-center border rounded-lg p-4 hover:shadow-lg transition">
            <img
              src={icon2} // Replace with customer image URL
              alt="Customers"
              className="w-16 h-16 mb-2"
            />
            <p className="text-gray-700 font-medium text-center">Customers</p>
          </div>
        </div>
        {/* Next Button */}
        <button className="w-full bg-orange-600 hover:bg-orange-700 text-white py-2 px-4 rounded-full font-semibold transition">
          Next
        </button>
      </div>
    </div>
  );
}
