import React from "react";
import facebook from "../assets/facebook.png";
import insta from "../assets/insta.png";
import twitter from "../assets/x.png";
import whatsapp from "../assets/whats.png";
import telegram from "../assets/tele.png";
import hero from "../assets/hero.png";
import himage1 from "../assets/himage1.png";
import himage2 from "../assets/himage2.png";
import himage3 from "../assets/himage3.svg";
import vectore from "../assets/Vector 1.png";
import car1 from "../assets/Dallas DFW Airport Car Rental.avif";
import car2 from "../assets/Aiport car rental DFW.avif";
import car3 from "../assets/fleet.avif";
import car4 from "../assets/Rent a car Dallas.avif";
import Frame from "../assets/Frame.png";
import Frame24 from "../assets/Frame 24.png";
import Frame25 from "../assets/Frame 25.png";
import Frame26 from "../assets/Frame 26.png";
import Frame27 from "../assets/Frame 27.png";
import Frame28 from "../assets/Frame 28.png";
import "./Home.css";
import Testimonials from "../components/Testimonials";
import Joinus from "../components/Joinus";
import About from "../components/About";
import WhyUs from "../components/whyus";
import { useNavigate } from "react-router-dom";
import FleetManagement from "../components/FleetManagement";

const Home = () => {
    const navigate = useNavigate();

    const carModels = [
      {
       img:car1,
       name:"Hyundai Santa Fe 2023",
       review:"4.96",
       passenger:"5 Passengers",
       door:"4 Doors",
       price:"62"
      },
      {
        img:car2,
        name:"Kia Telluride 2023",
        review:"5",
        passenger:"7 Passengers",
        door:"4 Doors",
        price:"77"
       },
       {
        img:car3,
        name:"Volkswagen Taos 2024",
        review:"4.96",
        passenger:"5 Passengers",
        door:"4 Doors",
        price:"75"
       },
      {
        img:car4,
        name:"Dodge Hornet 2024",
        review:"5",
        passenger:"5 Passengers",
        door:"4 Doors",
        price:"85"
       },
    ]

  return (
    <>
      <div
        className="flex justify-center items-center py-24 bg-no-repeat bg-right"
        style={{ backgroundImage: `url(${Frame})` }}
      >
        <div className="flex flex-col lg:flex-row  justify-between items-center w-full">
          <div className="flex flex-col lg:flex-row ml-4 lg:ml-0">
            <div className="flex flex-row lg:!flex-col mt-14 gap-4  lg:ml-24">
              <a
                href="https://www.facebook.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={facebook}
                  alt="Facebook"
                  className="w-10 h-10 transition-transform duration-300 hover:scale-110"
                />
              </a>
              <a
                href="https://www.instagram.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={insta}
                  alt="Instagram"
                  className="w-10 h-10 transition-transform duration-300 hover:scale-110"
                />
              </a>
              <a
                href="https://www.twitter.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={twitter}
                  alt="Twitter"
                  className="w-10 h-10 transition-transform duration-300 hover:scale-110"
                />
              </a>
              <a
                href="https://www.whatsapp.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={whatsapp}
                  alt="WhatsApp"
                  className="w-10 h-10 transition-transform duration-300 hover:scale-110"
                />
              </a>
              <a
                href="https://www.telegram.org/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={telegram}
                  alt="Telegram"
                  className="w-10 h-10 transition-transform duration-300 hover:scale-110"
                />
              </a>
            </div>

            {/* Hero Text Section */}
            <div
              className="max-w-3xl lg:ml-6 mt-8 lg:mt-0 bg-no-repeat"
              // style={{
              //   backgroundImage: `url(${vectore})`,
              //   // backgroundPosition: "11% 41%",
              // }}
            >
              <h1 className="text-5xl font-semibold">
              Hassle-Free Car Rentals in Dallas - <br /> Premium Service Delivered to Your 
              {" "}
                <span 
                 className="bg-gradient-to-r from-orange-500 to-orange-400 bg-clip-text text-transparent">
                  Terminal .
                </span>
              </h1>
              <img className="ml-16 -mt-0" src={vectore}/>
              <p className="mt-10 text-lg text-gray-800">
              "Traveling to Dallas?"
              With CatoDrive, we guarantee the exact car you booked, delivered straight to your terminal at DFW or Love Field Airport—no hassles, no surprises. Enjoy a seamless experience from booking to drop-off, with the convenience of premium service at the best price.
              </p>
              <button onClick={() => navigate("/ComingSoon")} className="mt-4 bg-orange-600 text-white rounded-lg py-2 px-3 font-medium text-lg hover:bg-orange-700">
                Rent Your Car
              </button>
              <button onClick={() => navigate("/ComingSoon")} className="mt-4 bg-orange-600 text-white rounded-lg ml-8 py-2 px-3 font-medium text-lg hover:bg-orange-700">
                List Your Car
              </button>
              {/* <div className="hero-cta-div">
              <button onClick={() => navigate("/ComingSoon")} className="mt-4 bg-orange-600 text-white rounded-lg py-2 px-6 font-medium text-lg hover:bg-orange-700">
              Reserve Your Car
              </button>
              <button onClick={() => navigate("/ComingSoon")} className="mt-4 ml-10 bg-orange-600 text-white rounded-lg py-2 px-6 font-medium text-lg hover:bg-orange-700">
              List Your Car
              </button>
              </div> */}
              <div className="hero-cta-div">
                <button className="cta-btn mt-4"></button>
                <button className="cta-btn1"></button>
              </div>
            </div>
          </div>

          {/* Hero Image */}
          <div className="max-w-xl mt-8 lg:mt-0">
            <img src={hero} alt="Hero" className="w-full h-auto rounded-lg" />
          </div>
        </div>
      </div>

      <div className="search-section w-full py-16 ">
        <div className="search-bar bg-white shadow-lg p-6 rounded-lg mx-auto max-w-7xl grid grid-cols-1 sm:grid-cols-4 gap-4 items-end">
          <div className="input-group col-span-1 sm:col-span-1">
            <label
              htmlFor="location"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              <i className="icon-location mr-2 text-blue-600"></i> Location
            </label>
            <input
              type="text"
              id="location"
              placeholder="Search your location"
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-200"
            />
          </div>
          <div className="input-group col-span-1 sm:col-span-1">
            <label
              htmlFor="pickup-date"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              <i className="icon-calendar mr-2 text-blue-600"></i> Pickup date
            </label>
            <input
              type="datetime-local"
              id="pickup-date"
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-200"
            />
          </div>
          <div className="input-group col-span-1 sm:col-span-1">
            <label
              htmlFor="return-date"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              <i className="icon-calendar mr-2 text-blue-600"></i> Return date
            </label>
            <input
              type="datetime-local"
              id="return-date"
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-200"
            />
          </div>
          <div className="col-span-1 sm:col-span-1">
            <button onClick={() => navigate("/ComingSoon")}  className="search-button w-full bg-[#d35400] text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition">
              Search
            </button>
          </div>
        </div>
      </div>

      <div className="how-section flex justify-center w-full py-16">
        <div className="how-div text-center">
          <p className="blue-box bg-blue-100 text-blue-600 px-4 py-2 rounded-full inline-block font-medium mb-6">
            How It Works
          </p>
          <h2 className="h2 text-4xl font-bold mb-8">
          Rent Your Car in 3 Easy Steps!
          </h2>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="card-box  p-6 text-center">
              <img
                src={himage1}
                alt="Choose location"
                className="w-24 h-24 mx-auto  object-contain"
              />
              <h3 className="text-lg font-semibold ">Choose Your Car</h3>
              <p className="text-sm text-gray-600">
              Select from Economy, Mid-Size, or Business Class options.
              </p>
            </div>

            <div className="card-box p-1  text-center">
              <img
                src={himage2}
                alt="Pick-up date"
                className="w-24 h-24 mx-auto  object-contain"
              />
              <h3 className="text-lg font-semibold ">Confirm Your Booking</h3>
              <p className="text-sm text-gray-600">
              Finalize your booking in minutes through our easy-to-use platform.              </p>
            </div>

            <div className="card-box  text-center">
              <img
                src={himage3}
                alt="Book your car"
                className="w-24 h-24 mx-auto  object-contain"
              />
              <h3 className="text-lg font-semibold ">Enjoy Your Trip</h3>
              <p className="text-sm text-gray-600">
              Your car will be waiting for you at the terminal, with a simple return process at the airport.              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className="brand-section"
        style={{
          overflow: "hidden",
          width: "100%",
          position: "relative",
          margin: "0 auto",
        }}
      >
        <div style={{ textAlign: "center", marginBottom: "40px" }}>
          <h2 style={{ fontSize: "32px", fontWeight: "bold", color: "#333" }}>
            Our Car Brands
          </h2>
        </div>

        <div
          style={{
            display: "flex",
            animation: "scroll 15s linear infinite",
          }}
        >
          <img
            src={Frame24}
            alt="Jaguar"
            style={{ width: "150px", margin: "0 40px", objectFit: "contain" }}
          />
          <img
            src={Frame25}
            alt="Nissan"
            style={{ width: "150px", margin: "0 40px", objectFit: "contain" }}
          />
          <img
            src={Frame26}
            alt="Volvo"
            style={{ width: "150px", margin: "0 40px", objectFit: "contain" }}
          />
          <img
            src={Frame27}
            alt="Audi"
            style={{ width: "150px", margin: "0 40px", objectFit: "contain" }}
          />
          <img
            src={Frame28}
            alt="Acura"
            style={{ width: "150px", margin: "0 40px", objectFit: "contain" }}
          />
          <img
            src={Frame24}
            alt="Jaguar"
            style={{ width: "150px", margin: "0 40px", objectFit: "contain" }}
          />
          <img
            src={Frame25}
            alt="Nissan"
            style={{ width: "150px", margin: "0 40px", objectFit: "contain" }}
          />
          <img
            src={Frame26}
            alt="Volvo"
            style={{ width: "150px", margin: "0 40px", objectFit: "contain" }}
          />
          <img
            src={Frame27}
            alt="Audi"
            style={{ width: "150px", margin: "0 40px", objectFit: "contain" }}
          />
          <img
            src={Frame28}
            alt="Acura"
            style={{ width: "150px", margin: "0 40px", objectFit: "contain" }}
          />
        </div>
        <style>
          {`
      @keyframes scroll {
        0% {
          transform: translateX(0);
        }
        100% {
          transform: translateX(-50%);
        }
      }
    `}
        </style>
      </div>
      <div className="popular-section w-full py-16">
        <div className="popular-div text-center">
          <p className="blue-box bg-blue-100 text-blue-600 px-4 py-2 rounded-full inline-block font-medium mb-6">
            POPULAR RENTAL DEALS
          </p>
          <h2 className="h2 text-3xl font-bold mb-12">
            Most popular cars rental deals
          </h2>

          <div className="car-cards  grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {carModels.map((car, index) => (
              <div
                key={index}
                className="card bg-white  rounded-lg shadow-lg p-6"
              >
                <img
                  src={car.img}
                  alt={car.name}
                  className="w-full h-48 object-cover  rounded"
                />
                <h3 className="text-lg font-semibold mb-2">
                  {car.name}
                </h3>
                <div className="rating flex items-center text-gray-600 text-sm ">
                  <span className="mr-2">⭐ {car.review}</span>
                  <p>({2436 - index * 500} reviews)</p>
                </div>
                <ul className="features text-sm text-gray-600 space-y-2 ">
                  <li>{car.passenger}</li>
                  <li>Auto</li>
                  <li>Air Conditioning</li>
                  <li>{car.door}</li>
                </ul>
                <div className="price text-gray-800 text-lg font-medium ">
                  <p>
                    <strong>${car.price}</strong>
                    /day
                  </p>
                </div>
                <button onClick={() => navigate("/ComingSoon")} className="r bg-blue-600 text-white py-2 px-4 rounded-lg w-full hover:bg-blue-700">
                  Rent Now →
                </button>
              </div>
            ))}
          </div>

          <button className="mt-12 flex items-center justify-center gap-2 px-4 py-2 border border-gray-300 rounded-lg bg-white text-gray-700 text-sm font-medium hover:bg-gray-100 transition">
            Show all vehicles
            <span className="text-lg">→</span>
          </button>
        </div>
      </div>

      <WhyUs />
      <FleetManagement/>
      <div className="testimo-section">
        <div className="testimo-div">
          <p className="blue-box">TESTIMONIALS</p>
          <h2 style={{ marginTop: '30px' }} className="h2">
  Trusted by Dallas Travelers
</h2>
        </div>
        <Testimonials />
      </div>
      <Joinus />
      <About />
    </>
  );
};

export default Home;
