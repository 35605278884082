import { Car, DollarSign, Clock, Shield } from 'lucide-react'
import { useNavigate } from "react-router-dom";
import fleet from "../assets/Dallas DFW Airport Car Rental.avif"
export default function FleetManagement() {
    const navigate = useNavigate();
  
  return (
    <section className="bg-gradient-to-br py-20">
      <div className="container mx-auto px-4">
        <div className="flex flex-col lg:flex-row items-center justify-between">
          <div className="lg:w-1/2 mb-8 lg:mb-0 p-8 bg-white/90 backdrop-blur-sm rounded-lg shadow-2xl">
            <h2 className="text-4xl lg:text-5xl font-bold mb-6 text-gray-800 leading-tight">
              Own a Car? <span className="text-blue-600">Let Us Manage It for You!</span>
            </h2>
            <p className="text-gray-600 mb-8 text-lg leading-relaxed">
              At CatoDrive, car owners can earn passive income without the hassle. We handle everything from bookings to maintenance.
            </p>
            <div className="grid grid-cols-2 gap-6 mb-8">
              <FeatureItem icon={<Car className="w-6 h-6 text-blue-500" />} text="Fleet Management" />
              <FeatureItem icon={<DollarSign className="w-6 h-6 text-green-500" />} text="Passive Income" />
              <FeatureItem icon={<Clock className="w-6 h-6 text-yellow-500" />} text="24/7 Support" />
              <FeatureItem icon={<Shield className="w-6 h-6 text-red-500" />} text="Full Insurance" />
            </div>
            <button onClick={() => navigate("/ComingSoon")} className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-full transition-all duration-300 transform hover:scale-105 hover:shadow-lg">
              List Your Car Today
            </button>
          </div>
          <div className="lg:w-1/2 lg:pl-12">
  <div className="relative group overflow-hidden rounded-lg">
    <img
      src={fleet}
      alt="Fleet Management Illustration"
      width={700}
      height={400}
      className="rounded-lg shadow-2xl transition-transform duration-300 group-hover:scale-105"
    />
    <div className="absolute inset-0 bg-blue-600 opacity-0 group-hover:opacity-20 transition-opacity duration-300"></div>
  </div>
</div>

        </div>
      </div>
    </section>
  )
}

function FeatureItem({ icon, text }) {
  return (
    <div className="flex items-center space-x-3">
      {icon}
      <span className="text-gray-700 font-medium">{text}</span>
    </div>
  )
}
