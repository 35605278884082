import React from "react";
import { BadgeCheck, Car, Clock, Headphones } from 'lucide-react';
import Car1 from "../assets/Group 1000004264.png";

export default function CarRentalHero() {
  return (
    <div className="flex flex-col lg:flex-row -mt-20">
      <div className="lg:w-1/2">
        <div className="">
          <img
            src={Car1}
            alt="Mercedes AMG GT"
            className="w-full h-full object-cover"
          />
        </div>
      </div>

      <div className="w-full lg:w-1/2 p-4 sm:p-8 flex items-center">
        <div className="max-w-3xl mx-auto">
          <div className="inline-block bg-blue-100 text-blue-600 px-4 py-2 rounded-full mb-6">
            <span className="text-sm font-medium">WHY CHOOSE US</span>
          </div>

          <h1 className="text-2xl sm:text-3xl lg:text-4xl font-bold mb-8 lg:mb-12 text-center">
            We offer the best experience with our rental deals
          </h1>

          <div className="flex flex-col gap-6 lg:gap-8">
          <Feature
  icon={BadgeCheck}
  title="Exact Car Guarantee"
  description="Get the car you booked, every time."
  extraDescription="No guarantee of the exact car model or make."
/>
<Feature
  icon={Car}
  title="No Cancellations"
  description="We'll never cancel your booking."
  extraDescription="Cancellations can occur last-minute."
/>
<Feature
  icon={Clock}
  title="Terminal Delivery"
  description="Car delivered directly to DFW & Love Field."
  extraDescription="You must take a shuttle or walk to a parking lot."
/>

            <Feature
              icon={Headphones}
              title="24/7 technical support"
              description="Have a question? Our support team is here to help you 24/7."
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function Feature({ icon: Icon, title, description }) {
  return (
    <div className="flex items-start gap-4">
      <div className="bg-blue-100 p-2 rounded-lg flex-shrink-0">
        <Icon className="w-6 h-6 sm:w-9 sm:h-9 text-blue-600" />
      </div>
      <div>
        <h3 className="font-semibold mb-1">{title}</h3>
        <p className="text-sm text-gray-600">{description}</p>
      </div>
    </div>
  );
}
