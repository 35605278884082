import { Check } from 'lucide-react'
import Img from "../assets/Img(6).png"
import { useNavigate } from 'react-router-dom'
export default function CarDetails() {
  const navigate = useNavigate()
  return (
    <div className="max-w-7xl mx-auto p-4 sm:p-6">
      <div className="mb-8">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6">
          <div>
            <h1 className="text-2xl font-bold">BMW</h1>
            <p className="text-xl text-blue-600 font-semibold">
              $25 <span className="text-sm text-gray-500">/d</span>
            </p>
          </div>
        </div>

        <div className="grid lg:grid-cols-[2fr,1fr] gap-6 lg:gap-8">
          <div className="relative bg-gray-100 rounded-lg aspect-[4/3] sm:aspect-[16/9]">
            <p className="absolute top-2 right-2 text-sm font-medium">360 View *</p>
            <img
              src={Img}
              alt="BMW car silhouette"
              className="object-contain w-full h-full"
            />
          </div>

          <div className="space-y-6">
            <h2 className="text-base font-medium">Technical Specification</h2>
            <div className="grid grid-cols-3 gap-4">
              {[
                { label: 'Gear Box', value: 'Manual' },
                { label: 'Fuel', value: 'Petrol' },
                { label: 'Doors', value: '4' },
                { label: 'Air Conditioner', value: 'Yes' },
                { label: 'Seats', value: '5' },
                { label: 'Distance', value: 'KM/L' },
              ].map((spec, index) => (
                <div key={index} className="bg-gray-50 p-2 rounded-lg">
                  <p className="text-sm font-bold text-gray-600">{spec.label}</p>
                  <p className="font-medium">{spec.value}</p>
                </div>
              ))}
            </div>

            <div className="flex gap-4">
              <button className="flex-1 py-3 px-6 bg-gray-100 rounded-lg text-gray-700">Price</button>
              <button onClick={() => navigate("/ComingSoon")} className="flex-1 py-3 px-6 bg-orange-500 text-white rounded-lg">Book Now</button>
            </div>

            <div className="space-y-4">
              <h2 className="text-base font-medium">Car Equipment</h2>
              <div className="grid grid-cols-2 gap-y-3">
                {['ABS', 'Air Bags', 'Cruise Control', 'Air Conditioner'].map((item) => (
                  <div key={item} className="flex items-center gap-2">
                    <div className="w-5 h-5 rounded-full bg-blue-500 flex items-center justify-center">
                      <Check className="w-3 h-3 text-white" />
                    </div>
                    <span className="text-sm text-gray-600">{item}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-4 mt-6 overflow-x-auto pb-2">
          {[1, 2, 3, 4].map((i) => (
            <div key={i} className="w-20 h-20 flex-shrink-0 bg-gray-200 rounded-lg overflow-hidden">
              <img
                src={Img}
                alt={`Car view ${i}`}
                className="w-full h-full object-cover"
              />
            </div>
          ))}
          <button className="w-20 h-20 flex-shrink-0 bg-gray-100 rounded-lg text-gray-400 flex items-center justify-center">
            More
          </button>
        </div>
      </div>

      <div>
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold">Other cars</h2>
          <a href="#" className="text-sm text-gray-600 hover:text-gray-900">
            View All →
          </a>
        </div>

        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {[
            { brand: 'Mercedes', type: '2020', price: 25 },
            { brand: 'Mercedes', type: 'Sport', price: 50 },
            { brand: 'Mercedes', type: 'Sedan', price: 45 },
          ].map((car, index) => (
            <div key={index} className="border rounded-lg p-4">
              <div className="aspect-[16/9] bg-gray-100 rounded-lg mb-4">
                <img
                  src={Img}
                  alt={`${car.brand} ${car.type}`}
                  className="w-full h-full object-contain"
                />
              </div>
              <div className="flex justify-between items-start mb-4">
                <div>
                  <h3 className="font-semibold">{car.brand}</h3>
                  <p className="text-sm text-gray-600">{car.type}</p>
                </div>
                <p className="text-blue-600 font-semibold">
                  ${car.price} <span className="text-gray-500 text-xs">/day</span>
                </p>
              </div>
              <div className="flex flex-wrap gap-2 mb-4">
                {['Manual', 'Petrol', 'AC'].map((feature) => (
                  <div key={feature} className="flex items-center gap-1 text-xs text-gray-600">
                    <span className="w-4 h-4 bg-gray-100 rounded-full" />
                    <span>{feature}</span>
                  </div>
                ))}
              </div>
              <button onClick={() => navigate("/ComingSoon")} className="w-full py-2 px-4 bg-orange-500 text-white rounded-lg font-medium">
                View Details
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

