import blog2 from "../assets/blog1.png"
import image1 from "../assets/image(2).png"
import image2 from "../assets/image(3).png"
import { CalendarDays, Clock } from 'lucide-react'
import Mask from "../assets/Mask.png"

export default function BlogArticles() {
    return (
      <>
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="text-center mb-12">
          <h1 className="text-3xl md:text-4xl lg:text-5xl flex justify-center font-bold mb-4">Catch Up with Our Latest Articles</h1>
          <p className="text-gray-600 text-sm md:text-md max-w-4xl mx-auto">
            Catch up with our latest news and stay up to keep on recent updates, insightful stories, and exciting
            announcements shaping our journey forward!
          </p>
        </div>
  
        <div className="relative h-[478px]  overflow-hidden rounded-md">
      <img
        src={blog2}
        alt="St. Mark's Basilica"
        className="absolute inset-0 h-full w-full object-cover"
      />
      <div className="absolute flex items-center inset-0 bg-gradient-to-t from-black/60 to-transparent" />
      <div className="absolute bottom-0 left-0 p-6">
        <h3 className="text-5xl font-bold text-white mb-2">
          The road trip places to visit in <br></br> Chennai!
        </h3>
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2">
            {/* <div className="h-8 w-8 rounded-full bg-gray-200" /> */}
            <span className="text-md text-white">By Martin Lawrence</span>
          </div>
          {/* <span className="text-sm text-white">5 min read</span> */}
        </div>
      </div>
    </div>
        <div className="space-y-6 mt-8 md:mt-16 flex flex-col md:flex-row justify-end">
          <div className="max-w-4xl">
            <p className="text-gray-600 text-sm md:text-base leading-relaxed">
              India brings you a journey through time and history to a unique experience that leaves an indelible mark on our understanding of the world. Today, we explore how India sets the city brimming with historical wonders, making us feel larger than life away from the bustling metropolis of Chennai. This brings us to Chennai city&apos;s many enthralling cultural hotspots, vibrant nightlife, and pleasant weather. We are all ready to explore the city&apos;s rich heritage and culture that gives it its old-school, yet still modern appeal during our journey of the past. We also reminisce the charm of the present.
            </p>
            
            <p className="text-gray-600 text-sm md:text-base leading-relaxed mt-4">
              Learn behind the urban buzz of Chennai, as that transforms to the most towards through a picturesque route that seamlessly blends. The diversity of experiences with the serenity of travel made easy. The journey from Chennai to Golden Temple enhances with our 100% air conditioned buses. The landscape transforms before our eyes, offering glimpses of rural life and the local character of the region. It&apos;s taking each road sign that we took appreciate the joy of leisurely drive.
            </p>
            
            <p className="text-gray-600 text-sm md:text-base leading-relaxed mt-4 mb-8">
              While the journey itself is an unforgettable part of the experience, being a cultural and comfortable vehicle car enhances the entire trip. Take in what Chennai to Golden Temple road trip offers, adding convenience and flexibility to mobility.
            </p>

            <div className="grid md:grid-cols-2 gap-4 mb-6">
              <img
                src={image1}
                alt="Traditional temple entrance"
                width={600}
                height={300}
                className="w-full h-[200px] md:h-[300px] object-cover rounded-lg"
              />
              <img
                src={image2}
                alt="Golden Temple view"
                width={600}
                height={300}
                className="w-full h-[200px] md:h-[300px] object-cover rounded-lg"
              />
            </div>

            <p className="text-center text-xs md:text-sm text-gray-500 mb-6">
              Our first stop on this expedition through time is the renowned Golden fort
            </p>

            <h3 className="text-xl md:text-2xl font-serif text-[#CD9003] text-center mb-4">
              "A Touch of Divinity: Sri Lakshmi Narayani Golden Temple"
            </h3>

            <p className="text-gray-600 text-sm md:text-base leading-relaxed">
              Located in the city of Vellore, Tamil Nadu, the Sri Lakshmi Narayani Temple, also known as the Vellore Golden Temple or Sripuram Golden Temple. The sight of this glorious temple adorned in shimmering gold is nothing short of breathtaking. Intricately designed with pure gold, it stands as a masterpiece of architecture, captivating visitors with its timeless appeal and spiritual energy. Whether you&apos;re a believer seeking solace or an admirer of beauty, the temple offers a chance for introspection and wonder.
            </p>
          </div>  
        </div>
      </div>

      <div className="mt-12 md:mt-20 bg-gray-100 px-4 md:px-8 py-8 md:py-16">
        <h2 className="text-lg md:text-xl font-bold mb-6 flex items-center gap-2">
          Related Posts
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {[1, 2, 3, 4].map((item) => (
            <div key={item} className="overflow-hidden rounded-lg shadow-md bg-white">
              <div className="relative h-48">
                <img
                  src="/placeholder.svg?height=200&width=400"
                  alt="Road trip"
                  layout="fill"
                  objectFit="cover"
                />
              </div>
              <div className="p-4">
                <div className="flex flex-wrap gap-2 mb-3">
                  <span className="px-3 py-1 text-xs font-medium rounded-full bg-green-50 text-green-700">
                    Road Trip
                  </span>
                  <span className="px-3 py-1 text-xs font-medium rounded-full bg-blue-50 text-blue-700">
                    Travel
                  </span>
                  <span className="px-3 py-1 text-xs font-medium rounded-full bg-purple-50 text-purple-700">
                    Travel Guide
                  </span>
                </div>
                <h3 className="font-semibold text-base md:text-lg mb-2">
                  The Ultimate Road Trip Packing Checklist: Don't Forget These Essentials!
                </h3>
                <p className="text-gray-600 text-xs md:text-sm mb-4">
                  The Ultimate Road Trip Packing Checklist: Don't Forget These Essentials!
                </p>
                <div className="flex items-center gap-4 text-gray-500 text-xs md:text-sm">
                  <div className="flex items-center gap-1">
                    <CalendarDays className="h-4 w-4" />
                    <span>June 3, 2024</span>
                  </div>
                  <div className="flex items-center gap-1">
                    <Clock className="h-4 w-4" />
                    <span>4</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div
        className="relative bg-blue-100 h-[300px] md:h-[450px] -mb-16 flex justify-center items-center"
        style={{
          backgroundImage: `url(${Mask})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <div className="absolute inset-0 opacity-50 z-0"></div>

        <div className="container mx-auto px-4 max-w-7xl text-center relative z-10">
          <h2 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4 text-black">
            Sign up to our Newsletter
          </h2>
          <p className="text-black text-lg md:text-xl lg:text-2xl mb-6">
            Stay up to date with the latest news announcements and articles
          </p>

          <div className="flex flex-col md:flex-row gap-4 max-w-2xl mx-auto">
            <input
              type="email"
              placeholder="Enter your email"
              className="flex-1 w-full md:w-40 px-4 py-2 rounded-md border border-black bg-transparent focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button className="px-6 py-2 bg-black text-white rounded-md hover:bg-gray-800 transition-colors">
              Subscribe
            </button>
          </div>
        </div>
      </div>
      </>
    )
  }

