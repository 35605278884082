import React, { useState } from 'react';
import Img from "../assets/Img+ button(1).png"
import Img1 from "../assets/Img(5).png"
import Img2 from "../assets/Frame 130(1).png"
import { Play, Check, ChevronDown , X } from 'lucide-react';
// import {} from 'lucide-react'


export default function WhyChooseUs() {
  const [openAccordion, setOpenAccordion] = useState(null);

  const toggleAccordion = (index) => {
    if (openAccordion === index) {
      setOpenAccordion(null);
    } else {
      setOpenAccordion(index);
    }
  };

  


  return (
    <section className="py-8 sm:py-12 md:py-16 lg:py-20 px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
      <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-center mb-8 sm:mb-12 lg:mb-16">Why Choose Us</h2>
      
      {/* Features Section */}
      <div className="grid gap-8 lg:grid-cols-2 mb-8 sm:mb-12 lg:mb-16">
        <div className="flex items-center">
          <h3 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-bold leading-tight">
            Where every drive feels extraordinary
          </h3>
        </div>

        <div className="grid gap-6 sm:gap-8 md:grid-cols-2 lg:grid-cols-3">
          <Feature 
            title="Variety Brands"
            description="Access our diverse fleet that suits every taste and need. From luxury brands to family-friendly vehicles."
          />
          <Feature 
            title="Maximum Freedom"
            description="Experience premium vehicles with easy extension options. No hidden charges for extended bookings."
          />
          <Feature 
            title="Flexibility On The Go"
            description="Make pickup easy and quick in our location. We ensure your experience is smooth and hassle-free."
          />
        </div>
      </div>

      {/* Statistics Section */}
      <div className="space-y-8 sm:space-y-12 mb-8 sm:mb-12 lg:mb-16">
  <div className="relative w-full max-w-7xl mx-auto rounded-lg overflow-hidden bg-gray-100" style={{ height: '300px', maxHeight: '500px' }}>
    <div className="absolute inset-0 bg-blue-100 rounded-lg overflow-hidden">
      <img
        src={Img}
        alt="Luxury car on the road"
        className="w-full h-full object-cover"
      />
    </div>
  </div>

  <div className="grid grid-cols-1 sm:grid-cols-3 gap-6 sm:gap-8 max-w-6xl mx-auto text-center">
    <Statistic number="20k+" label="Happy customers" />
    <Statistic number="540+" label="Expert of cars" />
    <Statistic number="25+" label="Years of experience" />
  </div>
</div>



      {/* Memories Section */}
      <div className="grid gap-8 lg:grid-cols-2 items-center mb-8 sm:mb-12 lg:mb-16">
        <div className="space-y-6 sm:space-y-8">
          <h3 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold leading-tight">
            Unlock unforgettable memories on the road
          </h3>
          <div className="grid gap-4">
            <MemoryItem text="Browse and book our luxury cars. We'll have it ready perfect waiting at your preferred location." />
            <MemoryItem text="Enjoy your premium vehicle with our top-notch service and flexible booking options." />
            <MemoryItem text="Return your car at any of our convenient locations across the city." />
            <MemoryItem text="Share your experience and join our community of satisfied customers." />
          </div>
        </div>

        <div className="relative aspect-[4/3] w-full">
          <div className="absolute inset-0 bg-blue-100 rounded-lg overflow-hidden">
            <img
              src={Img1}
              alt="Luxury car on the road"
              className="w-full h-full object-cover"
            />
          </div>
        </div>
      </div>
      <ComparisonTable/>

      {/* Download App Section */}
      <div className="bg-[#4B96F8] min-h-[400px] flex items-center justify-center mt-20 sm:mt-40 md:mt-60 lg:mt-80 px-4 py-16">
      <div className="max-w-6xl mx-auto flex flex-col md:flex-row items-center gap-8 md:gap-16">
        {/* Phone Mockup */}
        <div className="w-64 md:w-80 -mt-32 sm:-mt-48 md:-mt-72 relative">
          <div className="bg-black rounded-[3rem] p-3 relative">
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-40 h-6 bg-black rounded-b-3xl"></div>
            <div className="bg-white w-full aspect-[10/19.5] rounded-[2.3rem]"></div>
          </div>
        </div>

        {/* Content */}
        <div className="text-white text-center md:text-left max-w-lg">
          <p className="text-sm font-medium tracking-wide uppercase mb-2">
            Download our app
          </p>
          <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4">
            Download our app
          </h2>
          <p className="text-white/90 mb-8 text-base sm:text-lg">
            Turpis morbi enim nisi pulvinar leo dui tellus. Faucibus egestas semper
            diam rutrum dictumst ut donec. Nisi nisi morbi vel in vulputate. Nulla nam
            eget urna fusce vulputate at risus.
          </p>
          
          {/* App Store Buttons */}
          <div className="flex flex-col sm:flex-row items-center justify-center md:justify-start gap-4">
            <a 
              href="#" 
              className="block w-48 sm:ml-0 md:ml-10"
              aria-label="Download on the App Store"
            >
              <img 
                src="https://upload.wikimedia.org/wikipedia/commons/3/3c/Download_on_the_App_Store_Badge.svg"
                alt="Download on the App Store"
                className="w-full"
              />
            </a>
            <a 
              href="#" 
              className="block w-48"
              aria-label="Get it on Google Play"
            >
              <img 
                src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                alt="Get it on Google Play"
                className="w-full"
              />
            </a>
          </div>
        </div>
      </div>
    </div>



      {/* Reviews Section */}
      <div className="py-8 sm:py-12 md:py-16 lg:py-20">
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-center mb-8 sm:mb-12 lg:mb-16">Reviews from our customers</h2>
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
          <Review 
            quote="Et aliquet nunc et system perfectionist nulla tare dignissim neque. Nulla quis sagittis neque in dapibus Nulla quis tellus sit."
            author="Emmanuel Doyle"
          />
          <Review 
            quote="Porta consectetur tellus duis urna placerat purus nulla. Nam tincidunt nunc id dapibus. Lorem ipsum dolor sit amet adipiscing."
            author="Rose Greene"
          />
          <Review 
            quote="Quam neque ultricra autpretium felis. Sed egestas magna in dapibus Nulla quis tellus sit amet consectetur adipiscing. Lorem ipsum dolor sit amet adipiscing."
            author="Ryker Nelson"
          />
        </div>
      </div>

      {/* FAQ Section */}
      <div className="py-8 sm:py-12 md:py-16 lg:py-20">
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-center mb-8 sm:mb-12 lg:mb-16">Top Asked Questions</h2>
        <div className="max-w-6xl mx-auto">
          <Accordion
            items={[
              {
                question: "How does it work?",
                answer: "Imperdiet et tristique euismod nunc. Ultricies arcu vel accumsan cursus turpis ultricies neque. Pernullamper urna ut ac in. Proin nunc nunc mattis interdum et. Sed nunc id dapibus Nulla quis tellus sit amet consectetur adipiscing elit. Sed egestas magna in dapibus Nulla quis tellus sit."
              },
              {
                question: "Can I rent a car without a credit card?",
                answer: "Yes, you can rent a car without a credit card. We accept various payment methods including debit cards and digital payments. However, additional documentation may be required."
              },
              {
                question: "What are the requirements for renting a car?",
                answer: "To rent a car, you must be at least 21 years old, have a valid driver's license, provide proof of insurance, and meet our credit requirements. Additional requirements may apply for luxury vehicles."
              },
              {
                question: "Does Car+ drive allow me to tow with or attach a hitch to the rental vehicle?",
                answer: "Towing capabilities vary by vehicle. Please contact our customer service team to discuss specific towing requirements and available vehicles that meet your needs."
              },
              {
                question: "Does Car+ drive offer coverage products for purchase with my rental?",
                answer: "Yes, we offer various coverage options including collision damage waiver, personal accident insurance, and supplemental liability protection. Our team can help you choose the right coverage for your needs."
              }
            ]}
            openAccordion={openAccordion}
            toggleAccordion={toggleAccordion}
          />
        </div>
      </div>

       {/* Car Banner */}
       <CarBanner />
    </section>
  );
}

function Feature({ title, description }) {
  return (
    <div className="space-y-3">
      <h4 className="text-xl sm:text-2xl font-semibold">{title}</h4>
      <p className="text-base sm:text-lg text-gray-600 leading-relaxed">{description}</p>
    </div>
  );
}

function Statistic({ number, label }) {
  return (
    <div className="space-y-2">
      <div className="text-2xl sm:text-3xl md:text-4xl lg:text-6xl font-bold text-blue-600">{number}</div>
      <div className="text-base sm:text-lg text-gray-600">{label}</div>
    </div>
  );
}

function MemoryItem({ text }) {
  return (
    <div className="flex items-start gap-2">
      <div className="mt-1">
        <Check className="h-5 w-5 text-blue-600" />
      </div>
      <p className="text-sm text-gray-600">{text}</p>
    </div>
  );
}

function AppStoreButton({ store }) {
  return (
    <a href="#" className="bg-black hover:bg-gray-900 transition-colors rounded-lg px-6 py-3 flex items-center gap-2">
      <img
        src="/placeholder.svg?height=24&width=24"
        alt={store}
        className="w-6 h-6"
      />
      <span className="text-white">{store}</span>
    </a>
  );
}

function CarBanner() {
  return (
    <div className="bg-gradient-to-r from-blue-500 to-blue-400 rounded-2xl overflow-hidden relative mb-16 flex flex-col md:flex-row items-center">
      <div className="px-8 py-6 relative z-10 w-full md:w-1/2">
        <div className="max-w-xl">
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-white mb-2">
            Looking for a car?
          </h2>
          <p className="text-white/90 mb-2">
            +XXX-XXX-XXXX
          </p>
          <p className="text-white/80 text-sm mb-4">
            Amet vero hac eros massa. Faucibus ipsum arcu lectus nisl sapien bibendum ullamcorper in...
          </p>
          <button className="bg-orange-500 text-white px-6 py-2 rounded-lg font-medium hover:bg-orange-400 transition-colors">
            Book now
          </button>
        </div>
      </div>
      <div className="w-full md:w-1/2 h-64 md:h-96">
        <img
          src={Img2}
          alt="BMW 2-Series Coupe"
          className="object-cover w-full h-full"
        />
      </div>
      <div className="absolute inset-0 bg-gradient-to-r from-blue-500/50 to-transparent pointer-events-none" />
    </div>
  );
}

function Review({ quote, author }) {
  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden h-full flex flex-col">
      <div className="p-6 space-y-6 flex-grow">
        <div className="text-4xl sm:text-5xl text-[#2c6aa0]">"</div>
        <blockquote className="text-base sm:text-lg text-gray-600">
          Et aliquet netus at sapien pellentesque mollis nec dignissim maecenas. Amet erat volutpat quisque odio purus feugiat.
        </blockquote>
      </div>
      <div className="bg-[#2c6aa0] p-4">
        <div className="flex items-center gap-4">
          <div className="w-12 h-12 bg-white/20 rounded-full"></div>
          <div className="text-white">
            <div className="font-medium">{author}</div>
            <div className="text-sm text-white/80">Medical LLC</div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Accordion({ items, openAccordion, toggleAccordion }) {
  return (
    <div className="space-y-4">
      {items.map((item, index) => (
        <div key={index} className="border rounded-lg  overflow-hidden">
          <button
            className="flex justify-between items-center w-full p-4 text-xl text-left font-semibold"
            onClick={() => toggleAccordion(index)}
          >
            {item.question}
            <ChevronDown className={`w-5 h-5 transition-transform ${openAccordion === index ? 'transform rotate-180' : ''}`} />
          </button>
          {openAccordion === index && (
            <div className="p-4 bg-gray-50">
              <p>{item.answer}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}






const comparisonData = [
  {
    feature: "Exact Car Guarantee",
    catoDrive: "Get the car you booked, every time.",
    traditional: "No guarantee of the exact car model or make.",
  },
  {
    feature: "No Cancellations",
    catoDrive: "We'll never cancel your booking.",
    traditional: "Cancellations can occur last-minute.",
  },
  {
    feature: "Terminal Delivery",
    catoDrive: "Car delivered directly to DFW & Love Field.",
    traditional: "You must take a shuttle or walk to a parking lot.",
  },
  {
    feature: "Best Price Guarantee",
    catoDrive: "The best prices, no hidden fees.",
    traditional: "Often has hidden fees and higher rates.",
  },
  {
    feature: "Personalized Service",
    catoDrive: "Personalized assistance at no extra cost.",
    traditional: "Often no personal assistance provided.",
  },
  {
    feature: "Car Cleanliness",
    catoDrive: "Cleaned and disinfected after every ride.",
    traditional: "Cars may not be consistently clean or disinfected.",
  },
  {
    feature: "Convenience",
    catoDrive: "No shuttle, no lines, no hassle.",
    traditional: "Long queues, shuttle rides, and parking hassle.",
  },
]

 function ComparisonTable() {
  return (
    <div className="max-w-6xl mx-auto p-4 bg-gray-50 rounded-xl shadow-lg">
    <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">Why Choose CatoDrive?</h2>
    <div className="overflow-x-auto">
      <table className="w-full border-collapse bg-white rounded-lg overflow-hidden">
        <thead className="bg-blue-600 text-white">
          <tr>
            <th className="py-4 px-6 text-left font-semibold">Feature</th>
            <th className="py-4 px-6 text-left font-semibold">CatoDrive</th>
            <th className="py-4 px-6 text-left font-semibold">Traditional Car Rentals</th>
          </tr>
        </thead>
        <tbody>
          {comparisonData.map((item, index) => (
            <tr
              key={item.feature}
              className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
            >
              <td className="py-4 px-6 font-semibold text-gray-800">{item.feature}</td>
              <td className="py-4 px-6">
                <div className="flex items-start gap-2">
                  <Check className="w-5 h-5 text-green-600 mt-0.5 flex-shrink-0" />
                  <span className="text-gray-700">{item.catoDrive}</span>
                </div>
              </td>
              <td className="py-4 px-6">
                <div className="flex items-start gap-2">
                  <X className="w-5 h-5 text-red-600 mt-0.5 flex-shrink-0" />
                  <span className="text-gray-700">{item.traditional}</span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
  )
}




