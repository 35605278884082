import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import logo from '../assets/CatoDrive.png';

function Header() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const menuItems = [
    { text: 'Home', link: '/' },
    { text: 'Become a renter', link: '/ComingSoon' },
    { text: 'Vehicles', link: '/vehicles' },
    { text: 'Why choose us', link: '/whyus' },
    { text: 'Contact Us', link: '/contact' },
    { text: 'Blog', link: '/blog' },
  ];

  return (
    <div className="bg-gray-800 p-4 lg:p-6">
      <div className="container mx-auto flex flex-wrap items-center justify-between">
        {/* Logo */}
        <div className="flex-shrink-0">
        <img 
  onClick={() => navigate('/')} 
  src={logo} 
  alt="Logo" 
  className="h-8 cursor-pointer" 
/>
        </div>

        {/* Desktop Menu */}
        <div className="hidden lg:flex flex-grow items-center justify-center space-x-8">
          {menuItems.map((item, index) => (
            <a
              key={index}
              href={item.link}
              className="text-white text-lg hover:underline hover:decoration-2 hover:decoration-orange-500"
            >
              {item.text}
            </a>
          ))}
        </div>

        {/* Login/Signup Buttons */}
        <div className="hidden lg:flex items-center space-x-4">
          <button
            onClick={() => navigate('/ComingSoon')}
            className="text-white border border-white px-4 py-2 rounded-md hover:bg-white hover:text-gray-800"
          >
            Log in
          </button>
          <button
            onClick={() => navigate('/ComingSoon')}
            className="bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-orange-600"
          >
            Sign up
          </button>
        </div>

        {/* Mobile Menu Icon */}
        <div className="lg:hidden">
          <button onClick={toggleDrawer} className="text-white focus:outline-none">
            {drawerOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </div>
      </div>

      {/* Mobile Drawer */}
      {drawerOpen && (
        <div className="lg:hidden fixed inset-0 z-50 bg-gray-800 bg-opacity-90">
          <div className="flex flex-col h-full">
            <div className="flex justify-end p-4">
              <button
                onClick={toggleDrawer}
                className="text-white text-2xl focus:outline-none"
              >
                <X size={24} />
              </button>
            </div>
            <ul className="flex-grow flex flex-col items-center justify-center space-y-6">
              {menuItems.map((item, index) => (
                <li key={index}>
                  <a 
                    href={item.link} 
                    className="text-white text-xl hover:text-orange-500"
                    onClick={toggleDrawer}
                  >
                    {item.text}
                  </a>
                </li>
              ))}
            </ul>
            <div className="flex flex-col items-center space-y-4 p-6">
              <button
                onClick={() => {
                  navigate('/ComingSoon');
                  toggleDrawer();
                }}
                className="w-full text-white border border-white px-4 py-2 rounded-md hover:bg-white hover:text-gray-800"
              >
                Log in
              </button>
              <button
                onClick={() => {
                  navigate('/ComingSoon');
                  toggleDrawer();
                }}
                className="w-full bg-orange-500 text-white px-4 py-2 rounded-md hover:bg-orange-600"
              >
                Sign up
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;

