import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules"; 
import "swiper/css";
import "swiper/css/navigation";
import image1 from "../assets/Rectangle 8.png";

const testimonials = [
  {
    id: 1,
    name: "Sarah Wilson",
    location: "New York, US",
    rating: 5.0,
    feedback: "Nice car, fast ride. Was dropped off and delivered directly from and to the terminal. Worth every penny.",
    image: image1,
  },
  {
    id: 2,
    name: "Charlie Johnson",
    location: "From New York, US",
    rating: 5.0,
    feedback: "I feel very secure when using caretell's services. Your customer care team is very enthusiastic and the driver is always on time.",
    image: image1,
  },
  {
    id: 3,
    name: "Emily Brown",
    location: "California, US",
    rating: 5.0,
    feedback: "Nice car, fast ride. Was dropped off and delivered directly from and to the terminal. Worth every penny",
    image: image1,
  },
  {
    id: 4,
    name: "Charlie Johnson",
    location: "From New York, US",
    rating: 5.0,
    feedback: "Car was clean & comfortable. Mr Syed was very helpful from Checking in till we returned the car.Highly recommended.",
    image: image1,
  },
];

const Testimonials = () => {
  return (
    <section className="w-full text-center">
      <Swiper
        modules={[Navigation, Autoplay]}
        navigation
        autoplay={{ delay: 3000 }}
        loop
        spaceBetween={20}
        slidesPerView={1}
        breakpoints={{
          768: { slidesPerView: 2 },
          1024: { slidesPerView: 3 },
        }}
      >
        {testimonials.map((testimonial) => (
          <SwiperSlide key={testimonial.id}>
            <div className="bg-white rounded-lg shadow-lg text-left overflow-hidden flex flex-col items-center">
              <img src={testimonial.image} alt={testimonial.name} className="w-full h-72 object-cover" />
              <div className="p-5 w-full">
                <h3 className="text-yellow-500 text-xl mb-2">{testimonial.rating} stars</h3>
                <p className="text-gray-600 italic mb-4">"{testimonial.feedback}"</p>
                <p className="text-gray-800 font-bold text-lg">{testimonial.name}</p>
                <p className="text-gray-500 text-sm">{testimonial.location}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Testimonials;
