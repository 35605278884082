import React from 'react';
import iphone from '../assets/iPhone 14 Pro Space Black Mockup.png';
import vector from '../assets/Vector.png';

const About = () => {
  return (
    <div className="flex flex-col lg:flex-row w-full bg-white -mb-20 md:mt-20 relative">
      <div className="w-full lg:w-1/2 flex items-center justify-center relative p-6 lg:p-12">
        <img
          src={vector}
          alt="Vector"
          className="absolute bottom-0 w-full h-full max-w-full object-cover"
        />

        <div className="relative z-10 max-w-lg text-center lg:text-left lg:-ml-28">
          <button className="bg-blue-100 text-blue-600 font-semibold py-2 px-4 rounded-md mb-4">
            Download
          </button>
          <h1 className="text-3xl sm:text-4xl lg:text-5xl font-bold text-black mb-4">
            Download CatoDrive App for{' '}
            <span className="text-orange-500">FREE</span>
          </h1>
          <p className="text-gray-600 mb-6">
            For faster, easier booking and exclusive deals.
          </p>
          <div className="flex flex-wrap gap-4 justify-center lg:justify-start">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
              alt="Google Play"
              className="cursor-pointer w-[150px]"
            />
            <img
              src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
              alt="App Store"
              className="cursor-pointer w-[135px]"
            />
          </div>
        </div>
      </div>

      {/* Right Section */}
      <div className="w-full lg:w-1/2 flex items-center justify-center relative mt-20">
        <img
          src={iphone}
          alt="iPhone"
          className="lg:absolute lg:bottom-0"
        />
      </div>
    </div>
  );
};

export default About;
