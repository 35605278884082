import { ArrowRight } from 'lucide-react'
import { CalendarDays, Clock } from 'lucide-react'
import blog from "../assets/blog.png"
import blog2 from "../assets/blog1.png"
import Mask from "../assets/Mask.png"
import { useNavigate } from 'react-router-dom';

export default function Page() {
  const navigate = useNavigate();

  const handleImageClick = () => {
    navigate('/Blogarticles');
  };

  return (
    <main className="bg-white">
      <div className="container mx-auto px-4 sm:px-2 py-8 max-w-7xl">
        {/* Header */}
        <div className="text-center mb-12 px-4">
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4">
            Catch Up with Our Latest Articles
          </h1>
          <p className="text-gray-600 max-w-4xl mx-auto text-sm sm:text-base">
            Catch up with our latest news and stay up to keep on recent updates, insightful stories, and exciting
            announcements shaping our journey forward!
          </p>
        </div>

        {/* Recent Post Section */}
        <div className="mb-16">
  <p className="text-sm text-gray-600 mb-4">Recent Post (08)</p>
  <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
    <div className="cursor-pointer" onClick={handleImageClick}>
      <img
        src={blog2}
        alt="Venice architecture"
        className="rounded-lg object-cover w-full"
      />
    </div>
    <div className="bg-blue-50 p-6 sm:p-8 rounded-lg flex flex-col justify-between">
      <div>
        <div className="text-sm text-gray-600 mb-2">22 Dec 2024 • 4 min</div>
        <h2 className="text-lg sm:text-xl font-bold mb-3">
          The road trip places to visit in Chennai!
        </h2>
        <p className="text-sm md:text-base lg:text-lg text-gray-600">
        Embark on a journey through the rich cultural history of India's experience that takes you in markets and gives you understanding of the world. Venice, the author hub of India and the city brimming with historical wonders, awaits just a few hours drive away from the glowing metropolis of Chennai. This uniquely destination deserves a spot on every responsible, cultural explorer's...</p>

      </div>
      <button className="inline-flex items-center text-blue-600 hover:text-blue-700">
        Read Article
        <ArrowRight className="ml-2 h-4 w-4" />
      </button>
    </div>
  </div>
</div>


        {/* Latest Blog Posts Section */}
        <div className="mb-16">
          <h2 className="text-xl sm:text-2xl font-bold mb-6 text-center">Latest Blog and New Post</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {[1, 2, 3].map((item) => (
              <div key={item} className="overflow-hidden rounded-xl bg-white border border-gray-100">
                <div className="relative h-40 sm:h-48">
                  <img
                    src={blog}
                    alt="Road trip"
                    className="object-cover w-full h-full"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Weekly Most Read Section */}
        <div className="mb-16">
          <h2 className="text-lg sm:text-xl font-bold mb-6 flex items-center gap-2">
            Weekly Most Read <span className="text-orange-500">🔥</span>
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {[1, 2, 3].map((item) => (
              <div key={item} className="overflow-hidden rounded-lg shadow-md">
                <div className="relative h-40 sm:h-48">
                  <img
                    src="/placeholder.svg"
                    alt="Road trip"
                    className="object-cover w-full h-full"
                  />
                </div>
                <div className="p-4">
                  <div className="flex gap-2 mb-3 flex-wrap">
                    <span className="px-3 py-1 text-xs font-medium rounded-full bg-green-50 text-green-700">
                      Road Trip
                    </span>
                    <span className="px-3 py-1 text-xs font-medium rounded-full bg-blue-50 text-blue-700">
                      Travel
                    </span>
                    <span className="px-3 py-1 text-xs font-medium rounded-full bg-purple-50 text-purple-700">
                      Travel Guide
                    </span>
                  </div>
                  <h3 className="font-semibold text-base sm:text-lg mb-2">
                    The Ultimate Road Trip Packing Checklist
                  </h3>
                  <p className="text-gray-600 text-sm mb-4">
                    Essentials for your next adventure...
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Newsletter Section */}
        <div
          className="bg-blue-100 h-[350px] sm:h-[450px] flex flex-col items-center justify-center bg-cover bg-center"
          style={{ backgroundImage: `url(${Mask})` }}
        >
          <div className="text-center px-4">
            <h2 className="text-2xl sm:text-4xl font-bold mb-4 text-black">
              Sign up to our Newsletter
            </h2>
            <p className="text-black text-base sm:text-lg mb-6">
              Stay up to date with the latest news announcements and articles
            </p>
            <div className="flex flex-col sm:flex-row gap-4 max-w-md mx-auto">
              <input
                type="email"
                placeholder="Enter your email"
                className="flex-1 px-4 py-2 rounded-md border border-black bg-transparent focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button className="px-6 py-2 bg-black text-white rounded-md hover:bg-gray-800 transition-colors">
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
