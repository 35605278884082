import React from "react";
import { useNavigate } from "react-router-dom";
import location from "../assets/location.png";
import sms from "../assets/sms.png";
import call from "../assets/call.png";
import { Facebook, Instagram, Youtube } from 'lucide-react';

function Footer() {
  const navigate = useNavigate();

  const footerData = [
    {
      title: "CatoDrive",
      items: [
        { icon: location, text: "Lorem ipsum cycgic uycfsicsbocnsdiudng" },
        { icon: call, text: "XXXX-XXXX-XX" },
        { icon: sms, text: "catodrive@gmail.com" },
      ],
      isContact: true,
    },
    {
      title: "Our Product",
      items: ["Career", "Car", "Packages", "Features", "Priceline"],
      links: ["/ComingSoon", "/ComingSoon", "/ComingSoon", "/ComingSoon", "/ComingSoon"],
    },
    {
      title: "Resources",
      items: ["Download", "Help Centre", "Guides", "Partner Network", "Cruises", "Developer"],
      links: ["/ComingSoon", "/ComingSoon", "/ComingSoon", "/ComingSoon", "/ComingSoon" , "/ComingSoon"],
    },
    {
      title: "About Rentcars",
      items: ["Why choose us", "Our Story", "Investor Relations", "Press Center", "Advertise"],
      links: ["/ComingSoon", "/ComingSoon", "/ComingSoon", "/ComingSoon", "/ComingSoon"],
    },
  ];

  const socialIcons = [
    { icon: <Facebook size={28} />, link: "https://facebook.com" },
    { icon: <Instagram size={28} />, link: "https://instagram.com" },
    { icon: <Youtube size={28} />, link: "https://youtube.com" },
  ];

  return (
    <div className="bg-gray-800 text-gray-400 py-10 mt-16">
      <div className="container mx-auto px-4 lg:px-20">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-8">
          {/* CatoDrive Section */}
          <div className="col-span-1 sm:col-span-2 xl:col-span-1">
            <h4   onClick={() => navigate('/')} 
 className="text-white text-3xl mb-4 cursor-pointer">{footerData[0].title}</h4>
            <ul>
              {footerData[0].items.map((item, i) => (
                <li key={i} className="flex items-center mb-3">
                  <img src={item.icon} alt="icon" className="w-6 h-6 mr-3" />
                  <span className="text-sm">{item.text}</span>
                </li>
              ))}
            </ul>
          </div>

          {/* Main Sections */}
          {footerData.slice(1).map((section, index) => (
            <div key={index}>
              <h4 className="text-white text-2xl mb-4">{section.title}</h4>
              <ul>
                {section.items.map((item, i) => (
                  <li
                    key={i}
                    onClick={() => navigate(section.links[i])}
                    className="mb-3 cursor-pointer hover:text-orange-500 text-sm"
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          ))}

          {/* Social Media Section */}
          <div className="col-span-1 sm:col-span-2 lg:col-span-1">
            <h4 className="text-white text-xl mb-4">Follow Us</h4>
            <div className="flex gap-5">
              {socialIcons.map((social, index) => (
                <a
                  key={index}
                  href={social.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-orange-500"
                >
                  {social.icon}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="border-t border-gray-600 mt-10 pt-5">
        <div className="container mx-auto px-4 lg:px-20 flex flex-col sm:flex-row justify-between items-center">
          <p className="text-white text-sm mb-4 sm:mb-0">Copyright 2025 • CatoDrive, All Rights Reserved</p>
          <div className="flex gap-5 text-sm">
            <span
              onClick={() => navigate("/ComingSoon")}
              className="cursor-pointer hover:text-orange-500"
            >
              Privacy & Policy
            </span>
            <span
              onClick={() => navigate("/ComingSoon")}
              className="cursor-pointer hover:text-orange-500"
            >
              Terms & Condition
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;

